<template>
  
  <TheStandardLayout>
    <template #main>
      
      <h2 class="mb-3 mb-lg-5">Kontakt zur Friedhofsverwaltung</h2>
      
      <div class="row g-5">
        
        <div class="col-12 col-lg-7">
          
          <h4 class="mb-4">Kontaktformular</h4>
          
            <template v-if="messageSent">
              <div class="alert alert-success" role="alert">
                <strong>Vielen Dank für Ihre Nachricht.</strong><br>Wir setzen uns in Kürze für eine persönliche Beratung mit Ihnen in Verbindung.
              </div>
            </template>
          
            <Form @submit="sendEmail" :validation-schema="validationSchema" v-slot="{ meta }">
              
              <div class="mb-4">
                <Field name="name" id="name" v-model="contactForm.inputName" type="text" v-slot="{ field, meta }">
                  <label for="name" class="form-label" :class="generateInputClasses(meta)">Wie lautet Ihr Name?</label>
                  <input class="form-control" :class="generateInputClasses(meta)" v-bind="field" placeholder="Max Mustermann" />
                  <ErrorMessage as="div" name="name" v-slot="{ message }"><span class="error"><i class="far fa-times-circle"></i> {{ message }}</span></ErrorMessage>
                </Field>
              </div>
              
              <div class="mb-4">
                <Field name="email" id="email" v-model="contactForm.inputEmail" type="email" v-slot="{ field, meta }">
                  <label for="email" class="form-label" :class="generateInputClasses(meta)">Wie lautet Ihre E-Mail-Adresse?</label>
                  <input class="form-control" :class="generateInputClasses(meta)" v-bind="field" placeholder="mustermann@website.de" />
                  <ErrorMessage as="div" name="email" v-slot="{ message }"><span class="error"><i class="far fa-times-circle"></i> {{ message }}</span></ErrorMessage>
                </Field>
              </div>
  
              <div class="mb-4">
                <Field name="phone" id="phone" v-model="contactForm.inputPhone" v-slot="{ field, meta }">
                  <label for="phone" class="form-label" :class="generateInputClasses(meta)">Wie können wir Sie telefonisch erreichen?</label>
                  <input class="form-control" :class="generateInputClasses(meta)" v-bind="field" type="phone" placeholder="07181 123456789" />
                  <ErrorMessage as="div" name="phone" v-slot="{ message }"><span class="error"><i class="far fa-times-circle"></i> {{ message }}</span></ErrorMessage>
                </Field>
              </div>
              
              <div class="mb-4">
                <label for="inputContact" class="form-label">Wie sollen wir Sie bevorzugt kontaktieren?</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputContact" id="inputContact-1" v-model="contactForm.inputContact" value="1">
                  <label class="form-check-label" for="inputContact-1">E-Mail</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputContact" id="inputContact-2" v-model="contactForm.inputContact" value="2">
                  <label class="form-check-label" for="inputContact-2">Telefon</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputContact" id="inputContact-3" v-model="contactForm.inputContact" value="3">
                  <label class="form-check-label" for="inputContact-3">Egal</label>
                </div>
              </div>
              
              <div class="mb-4">
                <label for="inputNeed" class="form-label">Liegt ein aktueller Sterbefall vor oder möchten Sie vorsorgen? (optional)</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputNeed" id="inputGraveNeed-1" v-model="contactForm.inputGraveNeed" value="1">
                  <label class="form-check-label" for="inputGraveNeed-1">Sterbefall</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputNeed" id="inputGraveNeed-2" v-model="contactForm.inputGraveNeed" value="2">
                  <label class="form-check-label" for="inputGraveNeed-2">Vorsorge</label>
                </div>
              </div>
              
              <div class="mb-4">
                <label for="inputGraveAvailable" class="form-label">Ist bereits ein Grab auf einem Schorndorfer Friedhof vorhanden?</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputGraveAvailable" id="inputGraveAvailable-1" v-model="contactForm.inputGraveAvailable" value="1">
                  <label class="form-check-label" for="inputGraveAvailable-1">Nein.</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputGraveAvailable" id="inputGraveAvailable-2" v-model="contactForm.inputGraveAvailable" value="2">
                  <label class="form-check-label" for="inputGraveAvailable-2">Ja, ein Wahlgrab.</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputGraveAvailable" id="inputGraveAvailable-3" v-model="contactForm.inputGraveAvailable" value="3">
                  <label class="form-check-label" for="inputGraveAvailable-3">Ja, ein Reihengrab.</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="inputGraveAvailable" id="inputGraveAvailable-4" v-model="contactForm.inputGraveAvailable" value="4">
                  <label class="form-check-label" for="inputGraveAvailable-4">Ja, ich weiß nicht ob Reihen- oder Wahlgrab.</label>
                </div>
              </div>
              
              <div class="mb-4" v-if="contactForm.inputGraveAvailable != '1'">
                <Field name="gravename" id="gravename" v-model="contactForm.inputGravename" v-slot="{ field, meta }">
                  <label for="gravename" class="form-label" :class="generateInputClasses(meta)">Bei vorhandenem Grab: Wie lautet der Grabname oder der Name des letzten Verstorbenen?</label>
                  <input class="form-control" :class="generateInputClasses(meta)" v-bind="field" type="text" placeholder="" />
                  <ErrorMessage as="div" name="gravename" v-slot="{ message }"><span class="error"><i class="far fa-times-circle"></i> {{ message }}</span></ErrorMessage>
                </Field>
              </div>
              
              <div class="mb-4">
                <Field name="message" id="message" v-model="contactForm.inputMessage" v-slot="{ field, meta }">
                  <label for="phone" class="form-label" :class="generateInputClasses(meta)">Was möchten Sie uns mitteilen? (optional)</label>
                  <textarea class="form-control" :class="generateInputClasses(meta)" v-bind="field" placeholder="Nachricht" rows="7"></textarea>
                  <ErrorMessage as="div" name="message" v-slot="{ message }"><span class="error"><i class="far fa-times-circle"></i> {{ message }}</span></ErrorMessage>
                </Field>
              </div>
              
              <input type="hidden" :v-bind="contactForm.inputResultGraves" id="inputResultGraves" name="inputResultGraves">
              
              <template v-if="formIsLoading">
                <button type="submit" class="btn btn-primary" :disabled="formIsLoading">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  Nachricht wird gesendet
                </button>
              </template>
              
              <template v-else>
                <button class="btn btn-primary mt-1" type="submit" :disabled="!meta.valid"><i class="far fa-paper-plane"></i> Nachricht senden</button>
              </template>

            </Form>

        </div>

        <div class="col-12 col-lg-5">
          <h4 class="mb-4">So erreichen Sie uns</h4>
          <p>Das Team der Friedhofsverwaltung steht Ihnen gerne für eine persönliche Beratung zur Verfügung. Nutzen Sie gerne untenstehende Kontaktdaten oder lassen Sie uns über das Kontaktformular eine Nachricht zukommen.</p>
          <p>
            <strong>Adresse:</strong><br>
            Hungerbühlstraße 32-34<br>
            73614 Schorndorf
          </p>
          <p>
            <strong>Kontakt:</strong><br>
            E-Mail: <a href="mailto:friedhof@schorndorf.de">friedhof@schorndorf.de</a><br>
            Telefon: <a href="tel:071816023190">07181 602-3190</a>, <a href="tel:071816023191">-3191</a> und <a href="tel:071816023192">-3192</a>
          </p>
          <p>
            <strong>Öffnungszeiten:</strong><br>
            Montag bis Freitag: 8.30 bis 11.30 Uhr
          </p>
        </div>
      
      </div>
      
    </template>
  </TheStandardLayout>
  
</template>

<script>
import TheStandardLayout from '@/layouts/TheStandardLayout'
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import axios from 'axios'
import querystring from 'querystring'

export default {
  
  name: "KontaktPage",
  
  components: {
    
    TheStandardLayout,
    Field,
    Form,
    ErrorMessage,
    
  },
  
  data: function() {
    
    const validationSchema = yup.object({
      name:
        yup.string()
        .required('Bitte geben Sie Ihren Namen ein.')
        .min(5, 'Bitte geben Sie Ihren Namen ein.'),
      email:
        yup.string()
        .required('Bitte geben Sie eine E-Mail-Adresse ein.')
        .email('Bitte geben Sie eine gültige E-Mail-Adresse ein.'),
      phone:
        yup.string()
        .required('Bitte geben Sie eine Telefonnummer ein.')
        .min(9, 'Bitte geben Sie eine gültige Telefonnummer ein.'),
      /*message:
        yup.string()
        .required('Bitte geben Sie eine Nachricht ein.')
        .min(10, 'Bitte geben Sie eine Nachricht ein.'),*/
    });
    
    return {
      contactForm: {
        inputName: '',
        inputEmail: '',
        inputPhone: '',
        inputGraveNeed: '',
        inputGraveAvailable: '1',
        inputContact: '1',
        inputMessage: '',
        inputGravename: '',
        inputResultGraves: localStorage.getItem("resultGraves")
      },
      formIsLoading: false,
      messageSent: false,
      validationSchema,
    }
    
  },
  
  created() {


  },
  
  mounted() {

    
  },
  
  computed: {
    
  },
  
  methods: {

    generateInputClasses(meta) {
      const classes = []
      if(meta.touched) {
        if(meta.valid) {
          classes.push('input-valid')
        } else {
          classes.push('input-invalid')
        }
      }
      return classes
    },
    
    sendEmail: function(values, { resetForm }) {
      
      var that = this;
      
      this.formIsLoading = true;
      
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: 'https://grabwahlomat.klickmanufaktur.dev/_sendMail/mail.php',
          data: querystring.stringify(that.contactForm)
        })
        .then(response => {
          resolve(response)

          this.formIsLoading = false
          this.messageSent = true

          resetForm()

        })
        .catch(error => {
          console.log(error)
          this.formIsLoading = false
          reject(error)
        })
      })
      
    }
    
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/bootstrap-custom.scss";

label.form-label {
  display: block;
  cursor: pointer;
  font-weight: 600;
  font-size: .9rem;
  &.is-invalid {
    color: $danger;
  }
}

input.input-invalid,
textarea.input-invalid {
  border: 1px solid $danger;
  background: rgba($danger,0.025);
}

button i {
  padding-right: .3rem;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

a {
  font-weight: 600;
}

/*span.error {
  display: block;
  padding: .1rem .25rem;
  background: rgba($danger,0.1);
  border: 1px solid rgba($danger,0.5);
  color: $danger;
  border-radius: .25rem;
  font-size: .8rem;
  margin-top: .25rem;
}*/

span.error {
  color: $danger;
  font-size: .8rem;
}
  
</style>
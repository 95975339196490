<template>
  
  <TheStandardLayout>
    <template #main>
      
      <h2 class="mb-4">Ihr persönliches Grabwahlomat-Ergebnis</h2>
      
      <p class="mb-4"><strong>Folgende Grabarten haben die prozentual höchste Übereinstimmung mit Ihren Antworten.</strong> Bitte beachten Sie, dass die Ergebnisse nur unverbindliche Vorschläge sind und der Grabwahlomat keine individuelle Beratung durch unsere Friedhofsverwaltung ersetzt. Nehmen Sie gerne direkt Kontakt mit uns auf. Wenn Sie das Kontaktformular auf der nachfolgenden Seite nutzen, wird Ihr Ergebnis direkt mit an die Mitarbeiterinnen und Mitarbeiter übermittelt.</p>
      
      <div class="d-flex justify-content-between">
        <div>
          <button type="button" class="btn btn-secondary" @click="resetLocalStorage"><i class="far fa-home"></i> Grabwahlomat erneut starten</button>
        </div>
        <div>
          <button type="button" class="btn btn-primary" @click="$router.push('/kontakt')"><i class="far fa-user-headset"></i> Kontakt zur Friedhofsverwaltung</button>
        </div>
      </div>
      
      <div class="graveResults row row-cols-1 row-cols-md-3 mb-4 mt-2 g-4">
        
        <template v-if="showAllResults">
        
          <template v-for="grave in resultGraves" :key="grave.id">
            <div>
              <div class="graveItem">
                <img :src="grave.image" class="img-fluid">
                <div class="p-4 pb-4">
                  <div class="title fs-5 lh-2 mb-2" v-html="grave.title"></div>
                  <p class="mb-0">
                    <strong>Bestattungsform:</strong> {{grave.type}}<br>
                    <strong>Anzahl Bestattungen:</strong> {{grave.funerals}}<br>
                    <strong>Laufzeit:</strong> {{grave.lifetime}}<br>
                    <strong>Kosten:</strong> {{grave.cost}}
                  </p>
                </div>
              </div>
            </div>
          </template>
        
        </template>
        
        <template v-else>
          
          <template v-for="grave in resultGraves.slice(0,3)" :key="grave.id">
            <div>
              <div class="graveItem">
                <img :src="grave.image" class="img-fluid">
                <div class="p-4 pb-4">
                  <div class="title fs-5 lh-2 mb-2" v-html="grave.title"></div>
                  <p class="mb-0">
                    <strong>Bestattungsform:</strong> {{grave.type}}<br>
                    <strong>Anzahl Bestattungen:</strong> {{grave.funerals}}<br>
                    <strong>Laufzeit:</strong> {{grave.lifetime}}<br>
                    <strong>Kosten:</strong> {{grave.cost}}
                  </p>
                </div>
              </div>
            </div>
          </template>
          
        </template>
        
      </div>
      
      <p class="text-center mb-5">
        <button class="btn btn-secondary" @click="showAll" v-if="!showAllResults">weitere Grabarten anzeigen</button>
      </p>
      
      <p>Mehr Informationen zu Friedhöfen und Grabarten in Schorndorf finden Sie unter <a href="https://www.schorndorf.de/friedhöfe" target="_blank">www.schorndorf.de/friedhöfe</a> oder in unserer <a href="https://www.schorndorf.de/ceasy/resource/?id=19495&download=1" target="_blank">Informationsbroschüre "Formen der Bestattung"</a>.</p>
      
    </template>
  </TheStandardLayout>
  
</template>

<script>
import TheStandardLayout from '@/layouts/TheStandardLayout'

export default {
  
  name: "ResultPage",
  
  components: {
    
    TheStandardLayout
    
  },
  
  data: function() {
    
    return {
      answersArr: {},
      gravesObj: {},
      resultsObj: {},
      questionsObj: {},
      computedResults: [],
      resultsArr: [],
      resultsArrNew: [],
      resultGraves: [],
      lastStepID: null,
      showAllResults: false,
      graveType: null
    }
    
  },
  
  created() {

    this.gravesObj = this.$store.getters.graves
    this.questionsObj = this.$store.getters.questions

    this.graveType = JSON.parse(localStorage.getItem("answer1"))[0]

    for(let question of this.questionsObj) {
      this.answersArr[question.id] = JSON.parse(localStorage.getItem('answer' + question.id))
    }

  },
  
  mounted() {
    
    /*
     * Get ID of last question
     */
    const allQuestionsData = Object.values(this.$store.getters.questions).at(-1).id
    this.lastStepID = allQuestionsData

    /*
     * Generate results
     */
    for (let [questionID,answerLetter] of Object.entries(this.answersArr)) {
      
      const questionArray = JSON.parse(JSON.stringify(this.questionsObj))
      
      for(let letter of answerLetter) {
        
        const answerIndex = questionArray[questionID-1]['answers'].findIndex((answer) => answer.letter == letter)
        var answerArr = questionArray[questionID-1]['answers'][answerIndex]['graves']
        
        this.resultsArr = this.resultsArr.concat(answerArr)
        
        answerArr = []
        
      }
      
      if(this.graveType == 'A') {
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 5 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 6 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 7 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 8 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 9 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 10 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 11 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 12 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 13 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 14 })
      }
      
      if(this.graveType == 'B') {
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 1 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 2 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 3 })
        this.resultsArr = this.resultsArr.filter(function(f) { return f !== 4 })
      }
      
      const counts = {}
      
      this.resultsArr.forEach(function(x) { counts[x] = (counts[x] || 0)+1; })
      let entries = Object.entries(counts)
      this.computedResults = entries.sort((a, b) => b[1] - a[1])

    }

    let i;
    for (i = 0; i < this.computedResults.length; i++) {
      //if (i === 3) { break }
      //console.log(JSON.parse(JSON.stringify(this.computedResults[i])))

      let graveID = this.computedResults[i][0]
      
      let graveObj = this.gravesObj.find( graves => graves.id == graveID)
      
      this.resultGraves = this.resultGraves.concat(JSON.parse(JSON.stringify(graveObj)))
      localStorage.setItem('resultGraves', JSON.stringify(this.resultGraves))
      
    }
    
  },
  
  computed: {
    
  },
  
  methods: {
    
    resetLocalStorage() {
      localStorage.clear()
      this.$router.push('/')
    },
    
    showAll() {
      this.showAllResults = true
    }
    
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/bootstrap-custom.scss";

  div.graveResults {
    div.graveItem {
      overflow: hidden;
      border-radius: .5rem;
      border: 1px solid rgba($secondary,0.75);
      background-color: rgba($secondary,0.15);
      box-shadow: 0 0 4px rgba($secondary,.75);
      div.title {
        font-weight: 600;
        color: $primary;
      }
    }
  }
  
  p {
    a {
      font-weight: 600;
    }
  }
  
  button i {
    padding-right: .3rem;
  }
  
</style>
import { createRouter, createWebHistory } from "vue-router"
import StartPage from "@/pages/StartPage"
import QuestionPage from "@/pages/QuestionPage"
import ResultPage from "@/pages/ResultPage"
import KontaktPage from "@/pages/KontaktPage"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: StartPage
    },
    {
      path: "/frage/:questionID",
      component: QuestionPage
    },
    {
      path: "/ergebnis",
      component: ResultPage
    },
    {
      path: "/kontakt",
      component: KontaktPage
    },
  ]
})

export default router
import { createStore } from 'vuex'

import answersModule from './modules/answers'

const store = createStore({
  
  modules: {
    units: answersModule
  }
  
})

export default store
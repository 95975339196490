<template>
  
  <router-view></router-view>
  
</template>

<script>
//import LayoutNavBar from './components/Layout/LayoutNavbar'

export default {
  name: 'App',
  components: {
    //LayoutNavBar
  },
  data: function() {
    return {
    }
  }
}
</script>

<style>

</style>
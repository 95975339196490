<template>
  <div class="container">
    
    <div class="row wrapper bg-white p-2 p-md-4 p-lg-5 mt-0 mt-md-3 mt-lg-5 mx-0 mx-md-3 mx-lg-5">
      <div class="col-12">
        
        <div class="mb-3 mb-md-4 mb-lg-5 pb-4 d-flex justify-content-between align-items-center">
          <div class="">
            <h1>Grabwahlomat</h1>
          </div>
          <div class="">
            <a href="#" @click="$router.push('/')"><img src="@/assets/logo.svg" class="img-fluid" style="width:17.5rem"></a>
          </div>
        </div>
        
        <slot name="main">
          #MAIN
        </slot>
        
      </div>
    </div>
    
    <div class="row footer pe-5 pt-3 mb-5">
      <div class="col-12 d-flex justify-content-end">
        <a href="#" @click="this.$router.push('/kontakt')">Kontakt</a> <a href="https://www.schorndorf.de/datenschutz" target="_blank">Datenschutz</a> <a href="https://www.schorndorf.de/impressum" target="_blank">Impressum</a>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "TheStandardLayout"
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/bootstrap-custom.scss";

div.row.wrapper {
  border-radius: .5rem;
  box-shadow: 0px 0px 24px 0px rgba($gold, 0.14), 
              0px 8px 24px 0px rgba($gold, 0.04);
}

div.col-12 > div {
  border-bottom: 1px solid rgba($gold,0.25);
}

div.row.footer {
  a {
    margin-left: .75rem;
    text-decoration: none;
    color: $dark;
    font-size: .9rem;
    &:hover {
      color: $primary;
    }
  }
}

</style>
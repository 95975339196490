<template>
  
  <TheStandardLayout>
    <template #main>
      
      <div class="col-12 col-md-12 col-lg-10 col-xl-8 mx-auto">
      
        <h2 class="mb-4">Herzlich willkommen beim Grabwahlomat der Stadt Schorndorf.</h2>
        
        <p><i>„Die Menschheit ist ein Fluss des Lichtes, der aus der Endlichkeit zur Unendlichkeit fließt.“</i><br>— Khalil Gibran</p>
        
        <p><img src="@/assets/images/luftbild-header.jpg" class="img-fluid rounded my-2"></p>
        
        <p>Sich mit dem Ende des Lebens oder gar mit der eigenen Sterblichkeit zu befassen ist kein einfaches Thema.</p>

        <p>Allerdings kommt für jeden von uns einmal der Tag, an welchem wir von einem geliebten Menschen Abschied nehmen müssen.
          Sei es der Onkel, die Tante, die Großeltern oder die eigenen Eltern. Man muss sich um die Beerdigung kümmern und fragt
          sich unter anderem, was war ihm oder ihr wichtig? Wollte er/sie unter einem Baum bestattet werden oder in der Wiese?
          Ist es egal ob eine Sarg- oder Urnenbestattung ausgewählt wird? Soll ein pompöses Denkmal als Grabstein errichtet werden?
          Und was ist den Hinterblieben, die das Grab für die Bewältigung der Trauer besuchen kommen, wichtig? </p>

        <p>All diese Fragen gilt es am Besten im Vorfeld zu beantworten. Mit Hilfe unseres Grabwahlomaten möchten wir es Ihnen
          erleichtern, sich aktiv mit dem Thema der letzten Ruhestätte zu befassen. </p>

        <p>Hierzu stellen wir Ihnen ein paar grundlegende Fragen, über unsere verschiedenen Grabangebote. Anhand von diesen soll
          ermittelt werden, welche der über 20 Grabarten auf den Schorndorfer Friedhöfen die richtige sein könnte. Am Ende der
          Fragen kann Kontakt zur Friedhofsverwaltung aufgenommen werden. Diese berät gerne und umfassend über das (Vorsorge-)Angebot
          auf den Schorndorfer Friedhöfen. </p>
        
        <div class="mt-4 mt-lg-5 mb-4 mb-lg-0">
          <button type="button" class="btn btn-primary d-block w-100" @click="$router.push('/frage/1')">Grabwahlomat starten</button>
        </div>
      
      </div>
      
    </template>
  </TheStandardLayout>
  
</template>

<script>
import TheStandardLayout from '@/layouts/TheStandardLayout'

export default {
  name: "StartPage",
  components: {
    TheStandardLayout
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
  button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
  }
</style>

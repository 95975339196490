import { createApp } from 'vue'
import App from './App.vue'

// Import Router
import router from './router'
import store from './store'

// Import Fontawesome globally
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faTrash, faPlusCircle, faSpinner } from '@fortawesome/pro-regular-svg-icons'
library.add(faEdit, faTrash, faPlusCircle, faSpinner)

// Import Bootstrap CSS and JS
//import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/scss/bootstrap-custom.scss'
import 'bootstrap/dist/js/bootstrap.min.js'

// Create new Vue-app
const app = createApp(App)

// Use router
app.use(router)
app.use(store)

import { configure, defineRule } from 'vee-validate'
import AllRules from '@vee-validate/rules'

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
})

configure({
  validateOnInput: true,
})

// Use Fontawesome
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('font-awesome-layers', FontAwesomeLayers)
app.component('font-awesome-layers-text', FontAwesomeLayersText)

// Mount created app to div
app.mount('#app')
//import axios from 'axios'

const state = {
  
  questions: [
    {
      id: 1,
      question: 'Möchten Sie eine Erdbestattung (Sarg) oder Urnenbestattung?',
      answers: [
        { id: 1, letter: 'A', text: 'Erdbestattung', icon: 'fa-coffin-cross',
          graves: [
            1,
            2,
            3,
            4
          ]
        },
        { id: 2, letter: 'B', text: 'Urnenbestattung', icon: 'fa-burn',
          graves: [
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14
          ]
        },
        { id: 3, letter: 'C', text: 'Egal', icon: 'fa-question-circle',
          graves: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14
          ]
        },
      ],
      rowStyle: 'row-cols-1 row-cols-md-3 cols-2',
      multipleAnswers: false
    },
    {
      id: 2,
      question: 'Möchten Sie die Option haben das Grab (das Nutzungsrecht) zu verlängern?',
      answers: [
        { id: 1, letter: 'A', text: 'Ja', graves: [3,4,6,9,10,12,14] },
        { id: 2, letter: 'B', text: 'Nein', graves: [1,2,5,7,8,11,13] },
        { id: 3, letter: 'C', text: 'Egal', graves: [1,2,3,4,5,6,7,8,9,10,11,12,13,14] },
      ],
      rowStyle: 'row-cols-1 row-cols-md-3 cols-2 cols-small',
      multipleAnswers: false
    },
    {
      id: 3,
      question: 'Möchten Sie eine Beisetzung oder die Option haben, mehrere Personen bspw. Ihre/n Partner/in oder die Familie beizusetzen?',
      answers: [
        {
          id: 1,
          letter: 'A',
          text: 'Eine Beisetzung',
          graves: [
            1,1,1,
            2,2,2,
            5,5,5,
            7,7,7,
            8,8,8,
            11,11,11,
            13,13,13
          ] 
        },
        {
          id: 2,
          letter: 'B',
          text: 'Mehrere Beisetzungen',
          graves: [
            3,3,3,
            4,4,4,
            6,6,6,
            9,9,9,
            10,10,10,
            12,12,12,
            14,14,14
            
          ]
        },
        {
          id: 3,
          letter: 'C',
          text: 'Egal',
          graves: [
            1,1,1,
            2,2,2,
            3,3,3,
            4,4,4,
            5,5,5,
            6,6,6,
            7,7,7,
            8,8,8,
            9,9,9,
            10,10,10,
            11,11,11,
            12,12,12,
            13,13,13,
            14,14,14
          ]
        },
      ],
      rowStyle: 'row-cols-1 row-cols-md-3 cols-2 cols-small',
      multipleAnswers: false
    },
    {
      id: 4,
      question: 'Möchten Sie ein pflegearmes Grab (Kein Gießen oder Pflanzen)',
      answers: [
        { id: 1, letter: 'A', text: 'Ja', graves: [2,4,7,8,9,10,11,12,13,14] },
        { id: 2, letter: 'B', text: 'Nein', graves: [1,3,5,6] },
        { id: 3, letter: 'C', text: 'Egal', graves: [1,2,3,4,5,6,7,8,9,10,11,12,13,14] },
      ],
      rowStyle: 'row-cols-1 row-cols-md-3 cols-2 cols-small',
      multipleAnswers: false
    },
    {
      id: 5,
      question: 'Wollen Sie direkt auf dem Grab Grabschmuck wie Kerzen, Vasen oder Blumen ablegen oder dieses Bepflanzen?',
      answers: [
        { id: 1, letter: 'A', text: 'Grabkerzen', icon: 'fa-candle-holder', graves: [1,2,3,4,5,6,11,12,13,14] },
        { id: 2, letter: 'B', text: 'Dekoration', icon: 'fa-leaf', graves: [1,2,3,4,5,6,11,12,13,14] },
        { id: 3, letter: 'C', text: 'Bepflanzen mit Blumen', icon: 'fa-flower-daffodil', graves: [1,3,5,6] },
        { id: 4, letter: 'D', text: 'Blumenzwiebeln stecken', icon: 'fa-flower', graves: [1,2,3,4,5,6,7,8,9,10] },
        { id: 5, letter: 'E', text: 'Schnittblumen ablegen', icon: 'fa-flower-tulip', graves: [1,2,3,4,5,6,7,8,9,10] },
        { id: 6, letter: 'F', text: 'egal', icon: 'fa-question-circle', graves: [1,2,3,4,5,6,7,8,9,10,11,12,13,14] },
      ],
      rowStyle: 'row-cols-1 row-cols-md-2 cols-2',
      multipleAnswers: true
    },
    {
      id: 6,
      question: 'Auf welchem Friedhof soll das Grab sein?',
      answers: [
        { id: 1, letter: 'A', text: 'Neuer Friedhof',
          graves: [
            1,1,
            2,2,
            3,3,
            5,5,
            6,6,
            7,7,
            8,8,
            9,9,
            10,10,
            11,11,
            12,12,
            13,13,
            14,14,
          ]
        },
        { id: 2, letter: 'B', text: 'Buhlbronn',
          graves: [
            1,1,
            2,2,
            3,3,
            5,5,
            6,6,
            7,7,
            9,9,
          ]
        },
        { id: 3, letter: 'C', text: 'Haubersbronn',
          graves: [
            1,1,
            3,3,
            5,5,
            6,6,
            7,7,
            9,9
          ]
        },
        { id: 4, letter: 'D', text: 'Miedelsbach',
          graves: [
            1,1,
            2,2,
            3,3,
            4,4,
            5,5,
            6,6,
            7,7,
            9,9
          ]
        },
        { id: 5, letter: 'E', text: 'Oberberken',
          graves: [
            1,1,
            2,2,
            3,3,
            5,5,
            6,6,
            7,7,
            9,9
          ]
        },
        { id: 6, letter: 'F', text: 'Schlichten',
          graves: [
            1,1,
            3,3,
            5,5,
            6,6,
            7,7,
            9,9
          ] 
        },
        { id: 7, letter: 'G', text: 'Schornbach',
          graves: [
            1,1,
            2,2,
            3,3,
            5,5,
            6,6,
            7,7,
            9,9
          ]
        },
        { id: 8, letter: 'H', text: 'Unterberken',
          graves: [
            1,1,
            2,2,
            3,3,
            5,5,
            6,6,
            7,7,
            9,9
          ]
        },
        { id: 9, letter: 'I', text: 'Weiler',
          graves: [
            1,1,
            3,3,
            5,5,
            6,6,
            7,7,
            9,9
          ]
        },
        { id: 10, letter: 'J', text: 'Egal',
          graves: [
            1,1,
            2,2,
            3,3,
            4,4,
            5,5,
            6,6,
            7,7,
            8,8,
            9,9,
            10,10,
            11,11,
            12,12,
            13,13,
            14,14,
          ]
        },
      ],
      rowStyle: 'row-cols-1 row-cols-md-2 cols-2 cols-small',
      multipleAnswers: false
    },
    {
      id: 7,
      question: 'Welche Art und Lage gefallen Ihnen gut? ',
      answers: [
        {
          id: 1,
          letter: 'A',
          text: 'Wiese',
          graves: [2,4,7],
          image: 'https://grabwahlomat.klickmanufaktur.dev/_images/location/Wiese.jpg',
        },
        {
          id: 2,
          letter: 'B',
          text: 'Unter einem Baum',
          graves: [8,9],
          image: 'https://grabwahlomat.klickmanufaktur.dev/_images/location/Baum.jpg',
        },
        {
          id: 3,
          letter: 'C',
          text: 'Stele',
          graves: [10,10],
          image: 'https://grabwahlomat.klickmanufaktur.dev/_images/location/Stele.jpg',
        },
        {
          id: 4,
          letter: 'D',
          text: 'Grabfeld',
          graves: [1,2,5,6,13,14],
          image: 'https://grabwahlomat.klickmanufaktur.dev/_images/location/Grabfeld.jpg',
        },
        {
          id: 5,
          letter: 'E',
          text: 'Garten der Erinnerung',
          graves: [13,13,14,14],
          image: 'https://grabwahlomat.klickmanufaktur.dev/_images/location/Garten-der-Erinnerung.jpg',
        },
        {
          id: 6,
          letter: 'F',
          text: 'Himmelsgarten',
          graves: [11,11,12,12],
          image: 'https://grabwahlomat.klickmanufaktur.dev/_images/location/Himmelsgarten.jpg',
        },
      ],
      rowStyle: 'row-cols-1 row-cols-md-2 cols-2 cols-image',
      multipleAnswers: true
    },
  ],
  
  graves: [
    {
      id: 1,
      title: 'Erd&shy;reihen&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/erdgrab.jpg',
      type: 'Sarg',
      funerals: '1',
      lifetime: '20 Jahre',
      renewable: 'Nein',
      cost: '2.280 Euro',
      decorationPlanting: 'Bepflanzung mind. 50 % der Grabfläche; nur lebende Pflanzen und natürliche Produkte; Höhe max. 1,5 m; Besondere Vorschriften siehe Friedhofsatzung',
    },
    {
      id: 2,
      title: 'Erd&shy;rasen&shy;reihen&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Rasengraeber_Modellierung.jpg',
      type: 'Sarg',
      funerals: '1',
      lifetime: '20 Jahre',
      cost: '2.690 Euro',
    },
    {
      id: 3,
      title: 'Erd&shy;wahl&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Erdgrab-2.jpg',
      type: 'Sarg',
      funerals: '1 – 4',
      lifetime: '30 Jahre',
      cost: '3.730 – 7.310 Euro',
    },
    {
      id: 4,
      title: 'Erd&shy;rasen&shy;wahl&shy;grab (nur Miedels&shy;bach)',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Rasengraeber_Modellierung.jpg',
      type: 'Sarg',
      funerals: '2',
      lifetime: '30 Jahre',
      cost: '5.280 Euro',
    },
    {
      id: 5,
      title: 'Urnen&shy;reihen&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Urnengrab-1.jpg',
      type: 'Urne',
      funerals: '1',
      lifetime: '15 Jahre',
      cost: '1.300 Euro',
    },
    {
      id: 6,
      title: 'Urnen&shy;wahl&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Urnengrab-1.jpg',
      type: 'Urne',
      funerals: '2',
      lifetime: '30 Jahre',
      cost: '3.360 Euro',
    },
    {
      id: 7,
      title: 'Rasen&shy;urnen&shy;reihen&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Rasenurnengrab.jpg',
      type: 'Urne',
      funerals: '1',
      lifetime: '15 Jahre',
      cost: '1.470 Euro',
    },
    {
      id: 8,
      title: 'Baum&shy;urnen&shy;reihen&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Baumurnengrab.jpg',
      type: 'Urne',
      funerals: '1',
      lifetime: '15 Jahre',
      cost: '1.470 Euro',
    },
    {
      id: 9,
      title: 'Baum&shy;urnen&shy;wahl&shy;grab',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Baumurnengrab.jpg',
      type: 'Urne',
      funerals: '2',
      lifetime: '30 Jahre',
      cost: '3.830 Euro',
    },
    {
      id: 10,
      title: 'Stelenurnen-Wahlgrab (nur Neuer Friedhof)',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Stelenurnengrab.jpg',
      type: 'Urne',
      funerals: '2',
      lifetime: '30 Jahre',
      cost: '4.390 Euro',
    },
    {
      id: 11,
      title: 'Urnen&shy;reihen&shy;gemeinschafts&shy;grab (Himmels&shy;garten, nur Neuer Friedhof)',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/himmelsgarten.jpg',
      type: 'Urne',
      funerals: '1',
      lifetime: '15 Jahre',
      cost: '1.500 Euro',
    },
    {
      id: 12,
      title: 'Urnenwahl&shy;gemeinschfts&shy;grab (Himmels&shy;garten, nur Neuer Friedhof)',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/himmelsgarten.jpg',
      type: 'Urne',
      funerals: '2',
      lifetime: '30 Jahre',
      cost: '4.180 Euro',
    },
    {
      id: 13,
      title: 'Urnen&shy;reihen-Gemeinschafts&shy;grab (nur Neuer Friedhof)',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Urnengemeinschaftsgrab.jpg',
      type: 'Urne',
      funerals: '1',
      lifetime: '15 Jahre',
      cost: '1.270 Euro',
    },
    {
      id: 14,
      title: 'Urnen&shy;wahl&shy;gemeinschafts&shy;grab (nur Neuer Friedhof)',
      image: 'https://grabwahlomat.klickmanufaktur.dev/_images/graves/Urnengemeinschaftsgrab.jpg',
      type: 'Urne',
      funerals: '2',
      lifetime: '20 Jahre',
      cost: '2.290 Euro',
    },
  ]
  
}

const mutations = {
  
  ADD_ANSWER(state, id) {
    const index = state.graves.findIndex( graves => graves.id == id)
    state.graves[index].count++
  },
  
}

const actions = {

  addAnswer({commit}, payload) {
    commit('ADD_ANSWER', payload.id)
  },

}

const getters = {
  
  questions (state) {
    return state.questions
  },
  
  graves (state) {
    return state.graves
  },
  
  getQuestionData: (state) => (questionID) => {
    return state.questions.find( questions => questions.id == questionID)
  },
  
  getGravesFromAnswer: () => (answerLetter,questionData) => {
    return questionData.find( answers => answers.letter == answerLetter)
  },
  
}

const answersModule = {
  state,
  mutations,
  actions,
  getters
}

export default answersModule
<template>
  
  <TheStandardLayout>
    
    <template #main>

      <div class="col-12 col-md-12 col-lg-10 col-xl-10 mx-auto" :key="this.$route.params.questionID">
        
        <h4 class="mb-4">{{ questionID }}. {{ questionData.question }}</h4>
        
        <template v-if="questionData.multipleAnswers">
          
          <div class="row" :class="questionData.rowStyle">
            <template v-for="answer in questionData.answers" :key="answer.id">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" :name="generateAnswerID(questionData.id,answer.letter)" :id="generateAnswerID(questionData.id,answer.letter)" v-model="answerObj" :value="answer.letter">
                <label class="" :for="generateAnswerID(questionData.id,answer.letter)">
                  <div class="icon" v-if="answer.icon">
                    <i :class="answer.icon" class="fal fa-3x mb-2"></i>
                  </div>
                  <div class="image" v-if="answer.image">
                    <img :src="answer.image" class="img-fluid">
                  </div>
                  <div class="text">{{ answer.text }}</div>
                </label>
              </div>
            </template>
          </div>
          
        </template>
        
        <template v-else>
          
          <div class="row" :class="questionData.rowStyle">
            <template v-for="answer in questionData.answers" :key="answer.id">
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="generateAnswerID(questionData.id,answer.letter)" :id="generateAnswerID(questionData.id,answer.letter)" v-model="answerObj" :value="answer.letter">
                <label class="" :for="generateAnswerID(questionData.id,answer.letter)">
                  <div class="icon" v-if="answer.icon">
                    <i :class="answer.icon" class="fal fa-3x mb-2"></i>
                  </div>
                  <div class="text">{{ answer.text }}</div>
                </label>
              </div>
            </template>
          </div>
          
        </template>
        
        <div class="d-flex justify-content-between align-items-center">
          <div class="my-5 text-secondary">
            Schritt {{ questionID }} von {{ lastStepID }}
          </div>
          <div class="my-0 my-lg-5">
            <template v-if="lastQuestionID != 0">
              <button type="button" class="btn btn-secondary me-2" @click="buttonPreviousPage">zurück</button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-secondary me-2" @click="$router.push('/')">zurück zum Start</button>
            </template>
            <template v-if="questionID == lastStepID">
              <button type="button" class="btn btn-success" @click="buttonResult" :disabled="Object.keys(answerObj).length === 0">Ergebnis berechnen</button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-primary" @click="buttonNextPage" :disabled="Object.keys(answerObj).length === 0">weiter</button>
            </template>
          </div>
        </div>
      
      </div>
      
    </template>
    
  </TheStandardLayout>
  
</template>

<script>
import TheStandardLayout from '@/layouts/TheStandardLayout'

export default {
  
  name: "QuestionPage",
  
  data: function() {
    return {
      questionID: null,
      questionData: [],
      nextQuestionID: null,
      lastQuestionID: null,
      answerObj: [],
      answerCheckboxes: [],
      answerArr: [],
      lastStepID: null
    }
  },
  
  components: {
    TheStandardLayout
  },
  
  watch: {

  },
  
  created() {
    this.loadQuestionData(this.$route.params.questionID)
  },
  
  updated() {
    this.questionID = this.$route.params.questionID
    this.loadQuestionData(this.$route.params.questionID)
  },
  
  mounted() {
    const allQuestionsData = Object.values(this.$store.getters.questions).at(-1).id
    this.lastStepID = allQuestionsData
  },
  
  methods: {
    
    loadQuestionData(questionID2) {
      this.questionID = questionID2
      
      this.questionData = this.$store.getters.getQuestionData(this.questionID)
      
      if(this.questionData.multipleAnswers === true) {
        if(localStorage.getItem("answer" + this.questionID)) {
          this.answerObj = JSON.parse(localStorage.getItem("answer" + this.questionID))
        }
      } else {
        if(localStorage.getItem("answer" + this.questionID)) {
          this.answerObj = JSON.parse(localStorage.getItem("answer" + this.questionID)).toString()
        }
      }
      
      this.nextQuestionID = this.questionData.id + 1
      this.lastQuestionID = this.questionData.id - 1
    },
    
    generateAnswerID(questionID,answerLetter) {
      return 'answer' + questionID + answerLetter
    },
    
    saveAnswerToLocalStorage() {
      // Check if this.answerObj is array
      if(Object.prototype.toString.call(this.answerObj) === '[object Array]') {
        localStorage.setItem('answer'+this.questionData.id, JSON.stringify(this.answerObj))
      } else {
        localStorage.setItem('answer'+this.questionData.id, JSON.stringify([this.answerObj]))
      }
      // Reset this.answerObj
      this.answerObj = []
    },
    
    buttonPreviousPage() {
      this.saveAnswerToLocalStorage()
      this.$router.push('/frage/' + this.lastQuestionID)
    },
    
    buttonNextPage() {
      this.saveAnswerToLocalStorage()
      this.$router.push('/frage/' + this.nextQuestionID)
    },
    
    buttonResult() {
      this.saveAnswerToLocalStorage()
      this.$router.push('/ergebnis')
    }
    
  }
  
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/bootstrap-custom.scss";

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row.cols-1,
.row.cols-2.cols-small {
  
  .form-check {
    position: relative;
    padding-left: 0 !important;
  }
  
  .form-check input {
    z-index: 999;
    position: absolute;
    top: 1.1rem;
    left: 1.25rem;
    margin-left: 0 !important;
  }
  
  .form-check label {
    position: relative;
    color: $dark;
    background-color: white;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-content: center;
    cursor: pointer;
    border: 1px solid $secondary;
    border-radius: .5rem;
    padding: 1rem 3.15rem;
    margin-bottom: .5rem;
  }
  
  .form-check label div.icon {
    width: 100%;
    height: auto;
  }
  
  .form-check label div.text {
    display: block;
    width: 100%;
    font-weight: 600;
  }
  
  .form-check input:checked + label {
    border: 1px solid rgba($primary,0.4);
    background-color: rgba($primary,0.025);
    color: $primary;
    box-shadow: 0 0 4px rgba($primary,0.15);
  }

}

.row.cols-2 {
  
  .form-check {
    position: relative;
    padding-left: 0 !important;
  }
  
  .form-check input {
    z-index: 999;
    position: absolute;
    top: .75rem;
    left: 1.25rem;
    margin-left: 0 !important;
  }

  .form-check label {
    position: relative;
    color: $dark;
    background-color: white;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-content: center;
    cursor: pointer;
    border: 1px solid $secondary;
    border-radius: .5rem;
    height: 8rem;
    margin-bottom: .5rem;
  }
  
  .form-check label div.icon {
    width: 100%;
    height: auto;
  }
  
  .form-check label div.text {
    display: block;
    width: 100%;
    font-weight: 600;
  }
  
  .form-check input:checked + label {
    border: 1px solid rgba($primary,0.4);
    background-color: rgba($primary,0.025);
    color: $primary;
    box-shadow: 0 0 4px rgba($primary,0.15);
  }

}

.row.cols-2.cols-image,
.row.cols-2.cols-small {
  .form-check label {
    height: auto;
  }
}
.row.cols-2.cols-image {
  .form-check label {
    padding: 1rem 0;
  }
}
div.image {
  width: 100%;
  img {
    width: 70%;
    border-radius: .5rem;
    margin-bottom: .5rem;
  }
}

/*.form input:checked + label:after {
	content: "\2713";
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 100%;
	border: 2px solid #333;
	background-color: #2fcc71;
	z-index: 999;
	position: absolute;
	top: -10px;
	right: -10px;
}*/

</style>